export default defineNuxtRouteMiddleware(async (to) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn.value) {
    const { slug } = to.params;
    const lastHyphenIndex = slug.lastIndexOf("-");
    const collectionHashid = slug.substring(lastHyphenIndex + 1);

    const response = await $larafetch(`collections/${collectionHashid}/accept-invite`);
    return navigateTo(response.redirect_to, { replace: true });
  }
});
